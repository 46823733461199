// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// Licensed under the Amazon Software License
// http://aws.amazon.com/asl/

import * as appStore from '../models/AppStore';
import * as authenticationStore from '../models/AuthenticationStore';
import * as cleaner from '../models/Cleaner';
import * as currentUserStore from '../models/CurrentUserStore';
import * as idpStore from '../models/idp/IdpStore';
import * as appClientStore from '../models/app-client/AppClientStore';

// We can only do this trick thanks to Webpack
// see https://github.com/webpack/docs/wiki/context
function requireAll(requireContext) {
  return requireContext.keys().map(requireContext);
}

function register(contextModels) {
  // TODO: Figure out why the commented code below does not work
  // // requires and returns all modules that match
  // const modules = requireAll(require.context("../models", true, /^\.\/.*\.js$/));
  //
  // // is an array containing all the matching modules
  // _.forEach(modules, (model) => {
  //   if (_.isFunction(model.registerModels)) model.registerModels(contextModels);
  // });
  appStore.registerModels(contextModels);
  authenticationStore.registerModels(contextModels);
  cleaner.registerModels(contextModels);
  currentUserStore.registerModels(contextModels);
  idpStore.registerModels(contextModels);
  appClientStore.registerModels(contextModels);
}

export default register;
